<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$toPath('/web/sjz/jg/classList')">班级管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.push({path:'/web/sjz/jg/RollCallSet',query:{projectId:$route.query.projectId}})" >点名设置</a>
          <i>></i>
          <a href="javascript:;"  class="cur-a">小组点名设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexcb">
          <div class="studentnum">
            <!-- <span>未分组学员{{80}}</span> -->
          </div>
          <div class="df ci-full">
            <!-- <el-button class="bgc-bv" round @click="add()">学员分组</el-button> -->
            <el-button class="bgc-bv" round @click="fastSet">快速设置</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="df" style="align-items:flex-end;">
              <h2>{{groupName}}</h2>
              <span>（共{{userNum}}名学员）</span>
            </div>
            <RollCallPage ref="RollCallPage"></RollCallPage>
          </div>
        </div>
        <!-- <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" /> -->
        <div class="flexjc">
          <el-button class="bgc-bv" round @click="docencal">取消</el-button>
          <el-button class="bgc-bv" round @click="dosave">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import PageNum from "@/components/PageNum.vue";
import RollCallPage from "@/components/Rollcallpage.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "sjz/jg/RollCallSetDetail",
  components: {
    // Empty,
    // PageNum,
    RollCallPage
  },
  mixins: [List],
  data() {
    return {
      groupName:this.$route.query.groupName,
      userNum:this.$route.query.userNum,
    };
  },
  computed: {},
  methods: {
    fastSet(){
      this.$refs.RollCallPage.open();
    },
    // 确定
    dosave(){
      this.$refs.RollCallPage.doAjax();

    },
    // 取消
    docencal(){
      this.$router.back();
    },
  }
};
</script>
<style lang="less">
</style>
<style lang="less" scoped>
.studentnum {
  padding-left: 20px;
  font-size: 14px;
}
</style>

